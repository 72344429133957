import style from './SpeedGauge.module.css';
import { Box, Text } from '@chakra-ui/react';

const SpeedGauge = ({ speed, max_speed, disabled }: any) => {
  speed = Math.floor(speed);
  //speed = Math.floor(Math.random() * (240 - 0) + 0);
  const bar_width = Math.floor((speed / max_speed) * 100);
  const color =
    bar_width < 30 ? '#EA0A33' : bar_width < 65 ? '#EDFAA3' : '#3FD993';
  return (
    <Box
      className={style.root}
      style={
        { '--width': `${bar_width}%`, '--color': color } as React.CSSProperties
      }
    >
      <Text className={`${style.speed} ${speed === 0 ? style.inactive : ''}`}>
        {speed}
      </Text>
      <Box
        className={`${style.bar} ${speed > 199 ? style.godlike : ''}`}
        style={{ opacity: disabled ? 0 : 1 }}
      ></Box>
    </Box>
  );
};

export default SpeedGauge;
