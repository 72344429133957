import { useState, useEffect } from 'react';

import {
  Stack,
  Flex,
  Heading,
  Box,
  Spacer,
  Center,
  Text,
  SimpleGrid,
  List
} from '@chakra-ui/react';

import { useColorModeValue } from '@chakra-ui/react';

import { useSearchParams } from 'react-router-dom';

import OrganizationSelect from '../components/OrganizationSelect';

import { InformationListItem } from '../components/DataPresentation';
import useAxios from '../utils/useAxios';
import Navbar from '../components/Navbar';

const FillingDegree = () => {
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState<any>('');
  const [organizationData, setOrganizationData] = useState<any>({});
  const [fillingData, setFillingData] = useState<any>({});

  const [searchParams, setSearchParams] = useSearchParams();

  const api = useAxios();

  // Colors
  const secondaryColor = useColorModeValue('gray.900', 'yellow.100');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/organizations');
        setOrganizations(response.data.organizations);
      } catch {
        setOrganizations([]);
        // Raise error?
      }
    };
    fetchData();
    setSelectedOrganization(searchParams.get('organization-id'));
  }, []);

  useEffect(() => {
    // Fetch data when organization is selected
    if (selectedOrganization) {
      const fetchData = async () => {
        try {
          const response = await api.get(
            `/organizations/extended/${selectedOrganization}`
          );
          setOrganizationData(response.data);
          const fillingData = await api.get(
            `/filling-rate/${selectedOrganization}/filling-rate`
          );
          setFillingData(fillingData.data);
        } catch {
          setOrganizationData({});
          // Raise error?
        }
      };
      fetchData();
    } else {
      setOrganizationData({});
    }
  }, [selectedOrganization]);

  return (
    <Navbar>
      <Stack spacing={{ base: 6, md: 10 }}>
        <Flex>
          <Box as={'header'}>
            <Heading
              as="h1"
              lineHeight={1.1}
              fontWeight={600}
              color={secondaryColor}
              fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}
            >
              {organizationData.organization
                ? organizationData.organization.name
                : 'Filling degree'}
            </Heading>
          </Box>
          <Spacer />
          <Center>
            <OrganizationSelect
              organizations={organizations}
              selectedOrganization={selectedOrganization}
              setSelectedOrganization={setSelectedOrganization}
            />
          </Center>
        </Flex>
        {organizationData.organization &&
          Object.keys(fillingData).length !== 0 && (
            <>
              <Heading as="h2">Filling degree</Heading>
              <List spacing={2}>
                <InformationListItem
                  label="Total filling degree"
                  value={`${(fillingData.total_filling_ratio * 100)?.toFixed(1)} %`}
                  tooltipText="The total amount of space used in the system"
                />
                <InformationListItem
                  label="Bin utilization"
                  value={`${(fillingData.bin_utilization_ratio * 100)?.toFixed(1)} %`}
                  tooltipText="The ratio of bins in use by the system"
                />
                <InformationListItem
                  label="Compartment utilization"
                  value={`${(fillingData.compartment_utilization_ratio * 100)?.toFixed(1)} %`}
                  tooltipText="The ratio of compartments in use by the system (where at least one compartment is used in the bin)"
                />
                <InformationListItem
                  label="Average filling degree in a compartment"
                  value={`${(fillingData.compartment_filling_ratio * 100)?.toFixed(1)} %`}
                  tooltipText="The average ratio of space used in each compartment with an item"
                />
                <InformationListItem
                  label="Total number of items"
                  value={`${fillingData.total_number_of_items}`}
                  tooltipText="The total number of items in the system"
                />
                <InformationListItem
                  label="Total number of bins"
                  value={`${fillingData.total_number_of_bins}`}
                  tooltipText="The total number of bins in the system"
                />
                <InformationListItem
                  label="Average items per bin"
                  value={`${(fillingData.total_number_of_items / fillingData.total_number_of_bins)?.toFixed(1)}`}
                  tooltipText="The average number of items in each bin"
                />
                <InformationListItem
                  label="Estimated item capacity per bin"
                  value={`${fillingData.estimated_item_capacity_per_bin?.toFixed(1)}`}
                  tooltipText="The estimated number of items that can fit in each bin with 100% filling degree"
                />
              </List>
            </>
          )}
      </Stack>
    </Navbar>
  );
};

export default FillingDegree;
