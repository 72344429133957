import React, { ReactNode, useContext, useEffect } from 'react';
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  useColorModeValue,
  Link,
  Drawer,
  Icon,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList
} from '@chakra-ui/react';
import { ReactText } from 'react';

import AppContext from '../context/AppContext';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

import {
  ChevronDown,
  CircleGauge,
  House,
  LibraryBig,
  LucideIcon,
  Medal,
  MenuIcon,
  Percent,
  PocketKnife,
  ScrollText,
  Settings,
  Star,
  Truck,
  User
} from 'lucide-react';

interface LinkItemProps {
  name: string;
  href: string;
  icon: LucideIcon;
}
const baseURL = process.env.REACT_APP_API_URL;

const LinkItems: Array<LinkItemProps> = [
  { name: 'Home', href: '/', icon: House },
  { name: 'Scoreboard', href: '/picks', icon: Medal },
  { name: 'Dashboard', href: '/orgs', icon: CircleGauge },
  { name: 'Webhook logs', href: '/webhooklogs', icon: ScrollText },
  { name: 'Organization info', href: 'organization', icon: LibraryBig },
  { name: 'Orders', href: 'orders', icon: Truck },
  { name: 'Filling degree', href: 'filling', icon: Percent },
  { name: 'RAW', href: `${baseURL}/raw`, icon: PocketKnife }
  // { name: 'Settings', href: 'not-implemented', icon: Settings },
  // { name: 'Favourites', href: 'not-implemented', icon: Star },
];

function handleLogout(instance: any) {
  instance.logoutRedirect().catch((e: React.ChangeEvent<HTMLInputElement>) => {
    console.error(e);
  });
}

export default function Navbar({ children }: { children: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.1000')}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="6">
        {children}
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.1000')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="2xl" fontFamily="Akkurat" fontWeight="bold">
          Backoffice
        </Text>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map(link => (
        <NavItem key={link.name} icon={link.icon} href={link.href}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: LucideIcon;
  href: string;
  children: ReactText;
}
const NavItem = ({ icon, href, children, ...rest }: NavItemProps) => {
  return (
    <Link
      href={href}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'zest.400',
          color: 'gray.1200'
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="24"
            strokeWidth={1.5}
            _groupHover={{
              color: 'gray.1200'
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const { instance, accounts, inProgress } = useMsal();
  const { user } = useContext(AppContext);
  const name = accounts[0] && accounts[0].name;

  const navigate = useNavigate();

  useEffect(() => {
    // Check if admin or user is in user.roles, if not navigate to not-authorized
    if (
      user &&
      user.roles &&
      user.roles.length > 0 &&
      !user.roles!.includes('admin') &&
      !user.roles!.includes('user')
    ) {
      navigate('/not-authorized');
    }
  }, []);

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.1000')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<MenuIcon />}
      />

      <Text
        display={{ base: 'flex', md: 'none' }}
        fontSize="2xl"
        fontFamily="Akkurat"
        fontWeight="bold"
      >
        Backoffice
      </Text>

      <HStack spacing={{ base: '0', md: '2' }}>
        <ColorModeSwitcher justifySelf="flex-end" />
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}
            >
              <HStack>
                <User size={24} />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing={0}
                >
                  <Text fontSize="sm" lineHeight={1}>
                    {' '}
                    {name}{' '}
                  </Text>
                  <Text fontSize="xs" lineHeight={1} color="gray.600">
                    {// Print all user roles
                    user?.roles.map((role: any) => {
                      return role + ' ';
                    })}
                  </Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <ChevronDown size={24} />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
              <MenuItem onClick={() => navigate('/not-implemented')}>
                Profile
              </MenuItem>
              <MenuItem onClick={() => navigate('/not-implemented')}>
                Settings
              </MenuItem>
              <MenuItem> </MenuItem>
              <MenuDivider />
              <MenuItem onClick={() => handleLogout(instance)}>
                Sign out
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};
