import {
  Box,
  Container,
  Heading,
  Text,
  SimpleGrid,
  Stack,
  List,
  Input,
  useColorModeValue,
  StackDivider,
  Flex,
  Spacer,
  Center,
  Button,
  Link,
  Accordion,
  HStack
} from '@chakra-ui/react';

import { ExternalLink } from 'lucide-react';

import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useAxios from '../utils/useAxios';
import Navbar from '../components/Navbar';

import { InformationListItem } from '../components/DataPresentation';
import OrganizationSelect from '../components/OrganizationSelect';
import UserAccordionInfo from '../components/UserAccordionInfo';
import ShopAccordion from '../components/ShopAccordion';
import { mkConfig, generateCsv, download } from 'export-to-csv';

const OrganizationInfo = () => {
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState<any>('');
  const [organizationData, setOrganizationData] = useState<any>({});

  const [date3pl, handleDate3pl] = useState<string>('');

  const [searchParams, setSearchParams] = useSearchParams();

  const api = useAxios();

  // Colors
  const secondaryColor = useColorModeValue('gray.900', 'yellow.100');
  const subHeaderColor = useColorModeValue('gray.500', 'gray.400');
  const stackDividerColor = useColorModeValue('gray.200', 'gray.600');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/organizations');
        setOrganizations(response.data.organizations);
      } catch {
        setOrganizations([]);
        // Raise error?
      }
    };
    fetchData();
    setSelectedOrganization(searchParams.get('organization-id'));
  }, []);

  useEffect(() => {
    // Fetch data when organization is selected
    if (selectedOrganization) {
      const fetchData = async () => {
        try {
          const response = await api.get(
            `/organizations/extended/${selectedOrganization}`
          );
          setOrganizationData(response.data);
        } catch {
          setOrganizationData({});
          // Raise error?
        }
      };
      fetchData();
    } else {
      setOrganizationData({});
    }
  }, [selectedOrganization]);

  const handleExport = async (shopName: string, shopId: number) => {
    const response = await api.get(
      `/organizations/${selectedOrganization}/inventory_report/${shopId}`
    );
    const now = new Date();
    const csvConfig = mkConfig({
      useKeysAsHeaders: true,
      filename: `${shopName}_${now.getFullYear()}${now.getMonth()}${now.getDate()}`
    });
    const csv = generateCsv(csvConfig)(response.data.inventory);
    download(csvConfig)(csv);
  };

  const handleUsageReport = async () => {
    const report = await api.get(
      `/organizations/${selectedOrganization}/compartment_usage_report/${date3pl}`,
      { responseType: 'blob' }
    );
    const blob = report.data;
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // the filename you want
    a.download = `${organizationData.organization.name}.xlsx`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  console.log(organizationData);

  return (
    <Navbar>
      <Container maxW="100%" p={0}>
        <Stack spacing={{ base: 3, md: 5 }}>
          <Flex>
            <Box as={'header'}>
              <Heading
                lineHeight={1.1}
                fontWeight={600}
                color={secondaryColor}
                fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}
              >
                {organizationData.organization
                  ? organizationData.organization.name
                  : 'Organizations'}
              </Heading>
            </Box>
            <Spacer />
            <Center>
              <OrganizationSelect
                organizations={organizations}
                selectedOrganization={selectedOrganization}
                setSelectedOrganization={setSelectedOrganization}
              />
            </Center>
          </Flex>
          {Object.keys(organizationData).length !== 0 ? (
            <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={'column'}
              divider={<StackDivider borderColor={stackDividerColor} />}
            >
              <Box flexShrink={0}>
                <Link href={organizationData?.unify_link} isExternal>
                  <Button
                    color={secondaryColor}
                    variant="outline"
                    size="md"
                    ml={0}
                    isDisabled={!organizationData.unify_link}
                  >
                    Unify&nbsp;
                    <ExternalLink size="16" />
                  </Button>
                </Link>
                <Link href={organizationData?.unify_dashboard_link} isExternal>
                  <Button
                    color={secondaryColor}
                    variant="outline"
                    size="md"
                    ml={2}
                    isDisabled={!organizationData.unify_dashboard_link}
                  >
                    Power BI&nbsp;
                    <ExternalLink size="16" />
                  </Button>
                </Link>
                <Link
                  href={
                    process.env.REACT_APP_URL ===
                    'https://backoffice.getpio.com'
                      ? 'https://app.getpio.com/'
                      : 'https://app-beta.pio.com/'
                  }
                  isExternal
                >
                  <Button
                    color={secondaryColor}
                    variant="outline"
                    size="md"
                    ml={2}
                  >
                    PIO app&nbsp;
                    <ExternalLink size="16" />
                  </Button>
                </Link>
                <Text
                  color={subHeaderColor}
                  fontSize={'2xl'}
                  fontWeight={'300'}
                  mt={6}
                >
                  General information
                </Text>
              </Box>
              <SimpleGrid
                minChildWidth={{ base: '100%', md: '40%' }}
                spacing={{ base: 8, md: 10 }}
                py={{ base: 14, md: 8 }}
              >
                <Box>
                  <Text
                    fontSize={{ base: '16px', lg: '18px' }}
                    color={secondaryColor}
                    fontWeight={'500'}
                    textTransform={'uppercase'}
                    mb={'4'}
                  >
                    Information
                  </Text>
                  <List spacing={2}>
                    <InformationListItem
                      label="Created at"
                      value={
                        organizationData.organization.created_at.split('T')[0]
                      }
                      tooltipText="The date the organization was created"
                    />
                    <InformationListItem
                      label="Stub connected"
                      value={
                        organizationData.stub_service_registry ? 'Yes' : 'No'
                      }
                      tooltipText="If the stub is connected."
                    />
                    <InformationListItem
                      label="Shipping connector"
                      value={
                        organizationData.shipping_connector
                          ? organizationData.shipping_connector.api_url
                          : '-'
                      }
                      tooltipText="If a URL is specified, the shipping connector is connected."
                    />
                    <InformationListItem
                      label="Organization id"
                      value={organizationData.organization.id}
                      opacity="50%"
                      tooltipText="DEBUG: The id of the organization"
                    />
                    <InformationListItem
                      label="Autostore id"
                      value={organizationData.organization.autostore_id}
                      opacity="50%"
                      tooltipText="DEBUG: The id of the autostore in PIO db"
                    />
                    <HStack>
                      <Text>Select month</Text>
                      <Input
                        placeholder="Select Date and Time"
                        size="md"
                        width="auto"
                        type="date"
                        value={date3pl}
                        onChange={event => handleDate3pl(event.target.value)}
                      />
                      <Button
                        color={secondaryColor}
                        variant="outline"
                        size="md"
                        ml={0}
                        isDisabled={date3pl == ''}
                        onClick={() => handleUsageReport()}
                      >
                        Generate 3PL report
                        <ExternalLink size="16" />
                      </Button>
                    </HStack>
                  </List>
                </Box>
                <Box>
                  <Text
                    fontSize={{ base: '16px', lg: '18px' }}
                    color={secondaryColor}
                    fontWeight={'500'}
                    textTransform={'uppercase'}
                    mb={'4'}
                  >
                    Connected shops
                  </Text>
                  <Box>
                    {organizationData.shops.map((shop: any) => (
                      <Box>
                        <Accordion allowMultiple>
                          <ShopAccordion
                            key={shop.id}
                            shop={shop}
                            onExport={handleExport}
                          />
                        </Accordion>
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Box>
                  <Text
                    fontSize={{ base: '16px', lg: '18px' }}
                    color={secondaryColor}
                    fontWeight={'500'}
                    textTransform={'uppercase'}
                    mb={'4'}
                  >
                    Settings
                  </Text>
                  <List spacing={2}>
                    <InformationListItem
                      label="Picking task limit"
                      value={
                        organizationData.settings
                          ? String(organizationData.settings.picking_task_limit)
                          : '-'
                      }
                      tooltipText="The maximum number of picking tasks that can be qeued (?)"
                    />
                    <InformationListItem
                      label="Update shopify inventory"
                      value={
                        organizationData.settings
                          ? String(
                              organizationData.settings.update_shopify_inventory
                            )
                          : '-'
                      }
                      tooltipText="If true, the inventory of Shopify products is based on the inventory of the products in PIO"
                    />
                    <InformationListItem
                      label="Order processing delay"
                      value={
                        organizationData.settings
                          ? String(
                              organizationData.settings.order_processing_delay
                            )
                          : '-'
                      }
                      tooltipText="The delay in minutes between the creation of an order and the creation of the picking tasks (?)"
                    />
                    <InformationListItem
                      label="Bin selection algorithm version"
                      value={
                        organizationData.settings
                          ? String(
                              organizationData.settings
                                .bin_selection_algorithm_version
                            )
                          : '-'
                      }
                      tooltipText="The version of the bin selection algorithm"
                    />
                    <InformationListItem
                      label="Halt missing shipping rate"
                      value={
                        organizationData.settings
                          ? String(
                              organizationData.settings
                                .halt_missing_shipping_rate
                            )
                          : '-'
                      }
                      tooltipText="If true, the order is ... (?)"
                    />
                  </List>
                </Box>
                <Box>
                  <Text
                    fontSize={{ base: '16px', lg: '18px' }}
                    color={secondaryColor}
                    fontWeight={'500'}
                    textTransform={'uppercase'}
                    mb={'4'}
                  >
                    System overview
                  </Text>
                  <List spacing={2}>
                    <InformationListItem
                      label="Robots"
                      value={
                        organizationData.system_overview
                          ? String(organizationData.system_overview.robot_count)
                          : '-'
                      }
                      tooltipText="The number of robots in the system"
                    />
                    <InformationListItem
                      label="Ports"
                      value={
                        organizationData.system_overview
                          ? String(organizationData.system_overview.port_count)
                          : '-'
                      }
                      tooltipText="The number of ports in the system"
                    />
                    <InformationListItem
                      label="Bins"
                      value={
                        organizationData.system_overview
                          ? String(organizationData.system_overview.bin_count)
                          : '-'
                      }
                      tooltipText="The number of bins in the system"
                    />
                    <InformationListItem
                      label="Robots unavailable"
                      value={
                        organizationData.system_overview
                          ? String(
                              organizationData.system_overview
                                .robot_unavailable_count
                            )
                          : '-'
                      }
                      tooltipText="The number of robots that are unavailable"
                    />
                    <InformationListItem
                      label="Robots with error"
                      value={
                        organizationData.system_overview
                          ? String(
                              organizationData.system_overview
                                .robot_has_error_count
                            )
                          : '-'
                      }
                    />
                  </List>
                </Box>
                <Box>
                  <Text
                    fontSize={{ base: '16px', lg: '18px' }}
                    color={secondaryColor}
                    fontWeight={'500'}
                    textTransform={'uppercase'}
                    mb={'4'}
                  >
                    Connected users
                  </Text>
                  {/* List admins only */}
                  <Text
                    mb={2}
                    size="sm"
                    color={subHeaderColor}
                    fontWeight={'500'}
                  >
                    Admins
                  </Text>
                  <Box maxW={{ base: '100%', lg: '350' }}>
                    {organizationData.users?.map((user: any) =>
                      user.is_admin ? (
                        <Box>
                          <Accordion allowMultiple>
                            <UserAccordionInfo key={user.id} user={user} />
                          </Accordion>
                        </Box>
                      ) : null
                    )}
                  </Box>
                  {/* List managers */}
                  <Text
                    my={2}
                    size="sm"
                    color={subHeaderColor}
                    fontWeight={'500'}
                  >
                    Managers
                  </Text>
                  <Box maxW={{ base: '100%', lg: '350' }}>
                    {organizationData.users?.map((user: any) =>
                      user.is_manager ? (
                        <Box>
                          <Accordion allowMultiple>
                            <UserAccordionInfo key={user.id} user={user} />
                          </Accordion>
                        </Box>
                      ) : null
                    )}
                  </Box>
                  {/* List users */}
                  <Text
                    my={2}
                    size="sm"
                    color={subHeaderColor}
                    fontWeight={'500'}
                  >
                    Users
                  </Text>
                  <Box maxW={{ base: '100%', lg: '350' }}>
                    {organizationData.users?.map((user: any) =>
                      !user.is_admin && !user.is_manager ? (
                        <Box>
                          <Accordion allowMultiple>
                            <UserAccordionInfo key={user.id} user={user} />
                          </Accordion>
                        </Box>
                      ) : null
                    )}
                  </Box>
                </Box>
                <Box>
                  {organizationData.unify_data ? (
                    <Text
                      fontSize={{ base: '16px', lg: '18px' }}
                      color={secondaryColor}
                      fontWeight={'500'}
                      textTransform={'uppercase'}
                      mb={'4'}
                    >
                      Unify
                    </Text>
                  ) : null}
                  <List spacing={10}>
                    {organizationData.unify_data != null &&
                      organizationData.unify_data.bin_presentations?.map(
                        (port: any) => (
                          <List spacing={2}>
                            <InformationListItem
                              label="Port ID"
                              value={port.port_id}
                              tooltipText="The port ID"
                            />
                            <InformationListItem
                              label="Picks"
                              value={port.picks}
                              tooltipText="The number of picks in port"
                            />
                            <InformationListItem
                              label="Goods stored"
                              value={port.goods_in}
                              tooltipText="The number of goods stored at port"
                            />
                            <InformationListItem
                              label="Average wait for bin"
                              value={port.average_wait_bin}
                              tooltipText="The average time waiting for a bin at port"
                            />
                            <InformationListItem
                              label="Average wait for user"
                              value={port.average_wait_user}
                              tooltipText="The average time waiting for user interaction at port"
                            />
                          </List>
                        )
                      )}
                    {organizationData.unify_data != null &&
                      organizationData.unify_data.robot_movements?.map(
                        (robot: any) => (
                          <List spacing={2}>
                            <InformationListItem
                              label="Robot ID"
                              value={robot.robot_id}
                              tooltipText="The robot ID"
                            />
                            <InformationListItem
                              label="Avg distance per day"
                              value={robot.avg_per_day}
                              tooltipText="Average distance moved per day"
                            />
                            <InformationListItem
                              label="Total distance last day"
                              value={robot.total_day}
                              tooltipText="Total distance moved last day"
                            />
                            <InformationListItem
                              label="Total distance last week"
                              value={robot.total_week}
                              tooltipText="Total distance moved last week"
                            />
                            <InformationListItem
                              label="Active hours last week"
                              value={robot.active_hours_week}
                              tooltipText="Active hours last week"
                            />
                          </List>
                        )
                      )}
                    {organizationData.unify_data != null &&
                    organizationData.unify_data.grid_info ? (
                      <List spacing={2}>
                        <InformationListItem
                          label="Number of bins"
                          value={
                            organizationData.unify_data.grid_info.bins_in_grid
                          }
                          tooltipText="The current number of bins in the grid"
                        />
                        <InformationListItem
                          label="Max number of bins"
                          value={
                            organizationData.unify_data.grid_info
                              .bin_capacity_storage
                          }
                          tooltipText="The maximum number of bins possible"
                        />
                        <InformationListItem
                          label="Storage cells"
                          value={
                            organizationData.unify_data.grid_info.storage_cells
                          }
                          tooltipText="The total number of storage cells in the grid"
                        />
                      </List>
                    ) : null}
                  </List>
                </Box>
              </SimpleGrid>
            </Stack>
          ) : (
            ''
          )}
        </Stack>
      </Container>
    </Navbar>
  );
};

export default OrganizationInfo;
