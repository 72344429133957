import style from './PicksDashboard.module.css';
import { Text, Box, useColorMode, SimpleGrid, Flex } from '@chakra-ui/react';
import useAxios from '../utils/useAxios';
import { useEffect, useState } from 'react';
import { Clock, Hand } from 'lucide-react';
import { useSearchParams } from 'react-router-dom';

const getBarColor = (speed: number) => {
  if (speed < 60) {
    return '#EA0A33';
  }
  if (speed < 130) {
    return '#EDFAA3';
  }
  return '#3FD993';
};

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
const PicksDashboard = () => {
  const [statsData, setStatsData] = useState<any>({});
  const [scores, setScores] = useState([]);
  const { setColorMode } = useColorMode();
  const [searchParams, setSearchParams] = useSearchParams();

  const api = useAxios();
  useEffect(() => {
    setColorMode('dark');
    const fetchData = async () => {
      var filter = searchParams.get('org')
        ? '?org=' + searchParams.get('org')
        : '';
      try {
        const response = await api.get('/ops/picks' + filter);
        setStatsData(response.data);

        const score = await api.get('/ops/scoreboard' + filter);
        setScores(score.data.scores);
      } catch (e) {
        console.log('Error: ', e);
        setStatsData({});
        setScores([]);
      }
    };
    fetchData();
    const id = setInterval(() => fetchData(), 30000);
    return () => clearInterval(id);
  }, []);

  return (
    <SimpleGrid className={style.panels}>
      <SimpleGrid gap="0.25rem">
        <SimpleGrid
          className={style.panel}
          placeContent={'center'}
          pos={'relative'}
          style={{ containerType: 'inline-size' } as React.CSSProperties}
        >
          <Text className={style.panelHeading}>Picks today</Text>
          {statsData && statsData.picks_total ? (
            <Text className={style.panelLargeNumber}>
              {statsData.picks_today}
            </Text>
          ) : (
            <div></div>
          )}
        </SimpleGrid>
        <SimpleGrid
          className={style.panel}
          placeContent={'center'}
          position={'relative'}
          style={{ containerType: 'inline-size' } as React.CSSProperties}
        >
          <Text className={style.panelHeading}>All-time picks</Text>
          {statsData && statsData.picks_total ? (
            <Text className={style.panelLargeNumber}>
              {statsData.picks_total.toLocaleString('nb-NO')}
            </Text>
          ) : (
            ''
          )}
        </SimpleGrid>
      </SimpleGrid>

      <SimpleGrid gap={'0.25rem'} gridTemplateRows={'repeat(10, 1fr)'}>
        {scores.slice(0, 10).map((score, i) => (
          <Flex gap={'0.25rem'} alignItems={'stretch'} key={`score-${i}`}>
            <SimpleGrid className={style.panel} placeContent={'center'}>
              <Text
                fontSize={'clamp(1rem, -0.3043rem + 5.5652cqi, 3rem)'}
                fontFamily={'Akkurat Mono'}
                lineHeight={1}
                minWidth={'2ch'}
                textAlign={'center'}
              >
                {Math.floor(score['speed']) >= 200 ? '👑' : i + 1}
              </Text>
            </SimpleGrid>
            <SimpleGrid
              className={style.panel}
              flexGrow={1}
              gap={2}
              placeContent={'center stretch'}
            >
              <SimpleGrid
                gap={'clamp(0.5rem, 0.1739rem + 1.3913cqi, 1rem)'}
                style={{ containerType: 'inline-size' } as React.CSSProperties}
                gridTemplateColumns={'auto 1fr auto auto'}
                alignItems={'center'}
                justifyContent={'start'}
              >
                <Text
                  color="white"
                  fontSize={'clamp(1rem, 0.6739rem + 1.3913cqi, 1.5rem)'}
                  fontWeight={'700'}
                  textTransform={'uppercase'}
                  letterSpacing={'-0.02em'}
                  lineHeight={1.2}
                  textOverflow={'ellipsis'}
                  overflow={'hidden'}
                  whiteSpace={'nowrap'}
                >
                  {score['username']}
                </Text>
                <Text
                  color="gray.500"
                  fontSize={'clamp(0.875rem, 0.5489rem + 1.3913cqi, 1.375rem)'}
                  fontWeight={'500'}
                  letterSpacing={'-0.02em'}
                  lineHeight={1.2}
                  textOverflow={'ellipsis'}
                  overflow={'hidden'}
                  whiteSpace={'nowrap'}
                >
                  {score['org']}
                </Text>
                <Flex gap={'0.375rem'} alignItems={'center'}>
                  <Hand size={16} color="#D3D7DC" />
                  <Text
                    color={'gray.300'}
                    fontSize={
                      'clamp(0.875rem, 0.5489rem + 1.3913cqi, 1.375rem)'
                    }
                    lineHeight={1.2}
                    fontFamily={'Akkurat Mono'}
                  >
                    {score['tasks'] ?? 0}
                  </Text>
                </Flex>
                <Flex gap={'0.375rem'} alignItems={'center'}>
                  <Clock size={16} color="#D3D7DC" />
                  <Text
                    color={'gray.300'}
                    fontSize={
                      'clamp(0.875rem, 0.5489rem + 1.3913cqi, 1.375rem)'
                    }
                    lineHeight={1}
                    fontFamily={'Akkurat Mono'}
                    whiteSpace={'nowrap'}
                  >
                    {score['time'] >= 61 ? (
                      <Text as="span">
                        {Math.floor(score['time'] / 60)}
                        <Text as="span" fontFamily={'Akkurat'}>
                          h{' '}
                        </Text>
                        {Math.floor((score['time'] ?? 0) % 60)}
                        <Text as="span" fontFamily={'Akkurat'}>
                          m
                        </Text>
                      </Text>
                    ) : Math.floor(score['time']) === 60 ? (
                      <Text as="span">
                        1
                        <Text as="span" fontFamily={'Akkurat'}>
                          h
                        </Text>
                      </Text>
                    ) : (
                      <Text as="span">
                        {Math.floor((score['time'] ?? 0) % 60)}
                        <Text as="span" fontFamily={'Akkurat'}>
                          m
                        </Text>
                      </Text>
                    )}
                  </Text>
                </Flex>
              </SimpleGrid>

              <Box
                className={`${style.scoremeter} ${Math.floor(score['speed']) >= 200 ? style.godlike : ''}`}
                style={
                  {
                    '--width': `${Math.floor((score['speed'] * 100) / scores[0]['speed'])}%`,
                    '--color': getBarColor(score['speed'])
                  } as React.CSSProperties
                }
              />
            </SimpleGrid>
            <SimpleGrid className={style.panel} placeContent={'center'}>
              <Text
                fontSize={'clamp(1rem, -0.3043rem + 5.5652cqi, 3rem)'}
                fontFamily={'Akkurat Mono'}
                fontWeight={'bold'}
                lineHeight={1}
                minWidth={'3ch'}
                align={'center'}
                color={'white'}
              >
                {Math.floor(score['speed'])}
              </Text>
              <Text
                color="gray.500"
                align="center"
                fontSize={'clamp(0.625rem, 0.3804rem + 1.0435cqi, 1rem)'}
                lineHeight={1}
                letterSpacing={'-0.02em'}
                fontFamily={'Akkurat Mono'}
                whiteSpace={'nowrap'}
              >
                OL/H
              </Text>
            </SimpleGrid>
          </Flex>
        ))}
      </SimpleGrid>
    </SimpleGrid>
  );
};

export default PicksDashboard;
