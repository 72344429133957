import { useState, useCallback, useEffect } from 'react';
import React from 'react';
import {
  Heading,
  VStack,
  StackDivider,
  Box,
  useColorModeValue,
  HStack,
  Input,
  Button,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Alert,
  AlertIcon
} from '@chakra-ui/react';
import Navbar from '../components/Navbar';
import useAxios from '../utils/useAxios';
import { FiInfo } from 'react-icons/fi';
import {
  parse as parse_duration,
  toSeconds as durationToSeconds
} from 'iso8601-duration';

interface Hook {
  id: Number;
  event: string;
  url: string;
}
interface Shop {
  id: Number;
  name: string;
}
interface Organization {
  id: Number;
  name: string;
}

interface ExternalWebhooksOutboxLog {
  created_ats: string[];
  elapsed_times: string[];
  hook: Hook;
  log_ids: number[];
  organization: Organization;
  outbox_id: number;
  request_bodies: (string | null)[];
  response_bodies: (string | null)[];
  response_status_codes: (number | null)[];
  shop: Shop;
  statuses: string[];
  status_reasons: (number | null)[];
}

type ErrorDetails = {
  detail: {
    loc: string[];
    msg: string;
    [key: string]: any;
  }[];
};

function createErrorMessage(obj: ErrorDetails): string[] {
  return obj.detail.map(({ loc, msg }) => {
    if (!loc || !msg) {
      return 'Invalid error detail';
    }
    return `${loc[1]} -> ${msg}`;
  });
}

const JsonPopup: React.FC<{ jsonString: string; label: string }> = ({
  jsonString,
  label
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  let parsedJson: string;
  try {
    parsedJson = JSON.stringify(JSON.parse(jsonString), null, 2);
  } catch {
    parsedJson = 'Invalid JSON';
  }

  return (
    <>
      <Text
        color="teal.800"
        cursor="pointer"
        display="flex"
        alignItems="center"
        lineHeight="1"
        _hover={{ color: 'teal.600' }}
        onClick={onOpen}
      >
        View <FiInfo style={{ marginLeft: '4px' }} />
      </Text>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{label} JSON Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              whiteSpace="pre-wrap"
              fontSize="md"
              p={4}
              bg={useColorModeValue('gray.100', 'gray.800')}
              color={useColorModeValue('gray.900', 'gray.100')}
              borderRadius="md"
              boxShadow="md"
              maxHeight="500px"
              overflowY="auto"
            >
              {parsedJson}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const statusReasonLookup: { [key: number]: string } = {
  1: 'Undefined error.',
  2: 'The request timed out.',
  3: 'Network error.',
  4: 'Too many redirects.',
  5: 'Invalid URL provided.',
  6: 'Invalid status code'
};

const LogsTable = React.memo(
  ({ logGroups }: { logGroups: ExternalWebhooksOutboxLog[] }) => {
    const tableBgColor = useColorModeValue('gray.50', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.700');

    return (
      <Box
        bg={useColorModeValue('white', 'gray.900')}
        borderColor={borderColor}
        borderWidth="1px"
        rounded="md"
        p={4}
      >
        {logGroups.length > 0 ? (
          logGroups.map((group, index) => (
            <Box
              key={index}
              mb={8}
              p={4}
              bg={tableBgColor}
              rounded="md"
              boxShadow="sm"
            >
              <HStack spacing={4} mt={2} color="zest.800">
                <Box fontWeight="bold">
                  Outbox ID: {group.outbox_id || 'Unknown'}
                </Box>
                <Box fontWeight="bold">
                  Organization: {group.organization.name || 'Unknown'}
                </Box>
                <Box fontWeight="bold">
                  Shop: {group.shop.name || 'Unknown'}
                </Box>
                <Box fontWeight="bold">
                  Topic: {group.hook.event || 'Unknown'}
                </Box>
                <Box fontWeight="bold">URL: {group.hook.url || 'Unknown'}</Box>
              </HStack>
              <Table variant="simple" mt={4} size="sm" colorScheme="teal">
                <Thead>
                  <Tr>
                    <Th>Log ID</Th>
                    <Th>Status</Th>
                    <Th>Status Reason</Th>
                    <Th>Created At</Th>
                    <Th>Elapsed Time [s]</Th>
                    <Th>Request Body</Th>
                    <Th>Response Status Code</Th>
                    <Th>Response Body</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {group.log_ids.map((logId, idx) => (
                    <Tr key={idx} bg={idx % 2 === 0 ? tableBgColor : 'inherit'}>
                      <Td>{logId}</Td>
                      <Td>{group.statuses[idx]}</Td>
                      <Td>
                        {group.status_reasons &&
                        group.status_reasons[idx] !== null &&
                        group.status_reasons[idx] !== undefined
                          ? statusReasonLookup[
                              group.status_reasons[idx] as number
                            ] || ''
                          : ''}
                      </Td>
                      <Td>
                        {new Date(group.created_ats[idx]).toLocaleString()}
                      </Td>
                      <Td>
                        {durationToSeconds(
                          parse_duration(group.elapsed_times[idx])
                        ).toFixed(2)}
                      </Td>
                      <Td>
                        {group.request_bodies[idx] && (
                          <JsonPopup
                            jsonString={group.request_bodies[idx] || ''}
                            label="Request Body"
                          />
                        )}
                      </Td>
                      <Td>{group.response_status_codes[idx]}</Td>
                      <Td>
                        {group.response_bodies[idx] && (
                          <JsonPopup
                            jsonString={group.response_bodies[idx] || ''}
                            label="Response Body"
                          />
                        )}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          ))
        ) : (
          <Box p="4">No logs found</Box>
        )}
      </Box>
    );
  }
);

const LogsPage: React.FC = () => {
  const [logGroups, setLogGroups] = useState<ExternalWebhooksOutboxLog[]>([]);
  const [loading, setLoading] = useState(false);
  const [organization, setorganization] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [requestResponseBody, setRequestResponse] = useState<string>('');
  const [responseStatusCode, setResponseStatusCode] = useState<string>('');
  const [fromTimestamp, setFromTimestamp] = useState<string>(
    new Date().toISOString().split('T')[0]
  );
  const [toTimestamp, setToTimestamp] = useState<string>('');
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const api = useAxios();

  const buildQueryParams = () => {
    const queryParams = new URLSearchParams();
    if (organization) queryParams.append('organization', organization.trim());
    if (responseStatusCode)
      queryParams.append('response_status_code', responseStatusCode.trim());
    if (status) queryParams.append('status', status.trim());
    if (requestResponseBody)
      queryParams.append('request_response_body', requestResponseBody.trim());
    if (fromTimestamp)
      queryParams.append('from_timestamp', fromTimestamp.trim());
    if (toTimestamp) queryParams.append('to_timestamp', fromTimestamp.trim());
    return queryParams;
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    setErrorMessages([]);
    try {
      const queryParams = buildQueryParams();
      const response = await api.get(`/webhook/log?${queryParams.toString()}`);

      if (response.status >= 200 && response.status < 300) {
        const data: ExternalWebhooksOutboxLog[] =
          response.data.log_groups || [];
        setLogGroups(data);
      } else {
        setLogGroups([]); // Clear the page if response is not 2xx
        setErrorMessages([response.statusText]); // Show the response status text as a warning
      }
    } catch (error) {
      console.error('Error fetching logs:', error);
      setLogGroups([]); // Clear the page in case of an erroor
      const { response } = error as any;
      if (response && 'data' in response) {
        setErrorMessages(createErrorMessage(response.data));
      }
    }
    setLoading(false);
  }, [api, buildQueryParams]);

  useEffect(() => {
    if (!loading) {
      fetchData();
    }
  }, []); // Removed fetchData from dependency array to prevent infinite loop

  const handleFilter = () => {
    fetchData();
  };

  const dividerColor = useColorModeValue('gray.200', 'gray.600');
  const headingColor = useColorModeValue('zest.900', 'zest.100');

  return (
    <Navbar>
      <VStack
        spacing={6}
        align="stretch"
        divider={<StackDivider borderColor={dividerColor} />}
        p={4}
        minHeight="100vh"
      >
        <Heading
          lineHeight={1.1}
          fontWeight={700}
          color={headingColor}
          fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}
        >
          Logs
        </Heading>
        <HStack spacing={4} mb={4}>
          <Input
            placeholder="Filter by organization"
            value={organization}
            onChange={e => setorganization(e.target.value)}
            onKeyDown={e => e.key === 'Enter' && handleFilter()}
            maxWidth="300px"
          />
          <Input
            placeholder="Filter by Status"
            value={status}
            onChange={e => setStatus(e.target.value)}
            onKeyDown={e => e.key === 'Enter' && handleFilter()}
            maxWidth="300px"
          />
          <Input
            placeholder="Filter by request/response body"
            value={requestResponseBody}
            onChange={e => setRequestResponse(e.target.value)}
            onKeyDown={e => e.key === 'Enter' && handleFilter()}
            maxWidth="300px"
          />
          <Input
            placeholder="Filter by response status code"
            value={responseStatusCode}
            onChange={e => setResponseStatusCode(e.target.value)}
            onKeyDown={e => e.key === 'Enter' && handleFilter()}
            maxWidth="300px"
          />
          <Input
            placeholder="From timestamp (ISO 8601)"
            value={fromTimestamp}
            onChange={e => setFromTimestamp(e.target.value)}
            onKeyDown={e => e.key === 'Enter' && handleFilter()}
            maxWidth="300px"
          />
          <Input
            placeholder="To timestamp (ISO 8601)"
            value={toTimestamp}
            onChange={e => setToTimestamp(e.target.value)}
            onKeyDown={e => e.key === 'Enter' && handleFilter()}
            maxWidth="300px"
          />
          <Button onClick={handleFilter} colorScheme="teal">
            Filter
          </Button>
        </HStack>
        {errorMessages.length > 0 && (
          <Box mb={4}>
            {errorMessages.map((message, index) => (
              <Alert status="error" key={index}>
                <AlertIcon />
                {message}
              </Alert>
            ))}
          </Box>
        )}
        {loading ? (
          <Box p="4">Loading...</Box>
        ) : (
          <LogsTable logGroups={logGroups} />
        )}
      </VStack>
    </Navbar>
  );
};

export default LogsPage;
