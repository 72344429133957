const ShelfIcon = (props: any) => {
  return (
    <svg
      className={props.className}
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.25 21C1.25 21.4142 1.58579 21.75 2 21.75C2.41421 21.75 2.75 21.4142 2.75 21H1.25ZM21.25 21C21.25 21.4142 21.5858 21.75 22 21.75C22.4142 21.75 22.75 21.4142 22.75 21H21.25ZM6 19H5.25V19.75H6V19ZM12 19V19.75H12.75V19H12ZM12 11H11.25V11.75H12V11ZM18 11V11.75H18.75V11H18ZM2.75 21V5H1.25V21H2.75ZM4 3.75H20V2.25H4V3.75ZM21.25 5V21H22.75V5H21.25ZM20 3.75C20.6904 3.75 21.25 4.30964 21.25 5H22.75C22.75 3.48122 21.5188 2.25 20 2.25V3.75ZM2.75 5C2.75 4.30964 3.30964 3.75 4 3.75V2.25C2.48122 2.25 1.25 3.48122 1.25 5H2.75ZM2 19.75H22V18.25H2V19.75ZM2 11.75H22V10.25H2V11.75ZM6 19.75H12V18.25H6V19.75ZM12.75 19V16H11.25V19H12.75ZM11 14.25H7V15.75H11V14.25ZM5.25 16V19H6.75V16H5.25ZM7 14.25C6.0335 14.25 5.25 15.0335 5.25 16H6.75C6.75 15.8619 6.86193 15.75 7 15.75V14.25ZM12.75 16C12.75 15.0335 11.9665 14.25 11 14.25V15.75C11.1381 15.75 11.25 15.8619 11.25 16H12.75ZM12 11.75H18V10.25H12V11.75ZM18.75 11V8H17.25V11H18.75ZM17 6.25H13V7.75H17V6.25ZM11.25 8V11H12.75V8H11.25ZM13 6.25C12.0335 6.25 11.25 7.0335 11.25 8H12.75C12.75 7.86193 12.8619 7.75 13 7.75V6.25ZM18.75 8C18.75 7.0335 17.9665 6.25 17 6.25V7.75C17.1381 7.75 17.25 7.86193 17.25 8H18.75Z"
        fill={props.color}
      />
    </svg>
  );
};

export default ShelfIcon;
